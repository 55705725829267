import { soundsFolder, IS_DEVELOPMENT } from "../firebase";

export async function convertAndUploadFile(sound_id, file) {
    const convertedFile = await convertFileToMp3Blob(file)
    const filename = sound_id + '.mp3';
    await uploadToStorage(sound_id, filename, convertedFile);
    return urlForSound(filename);
}

async function convertFileToMp3Blob(file) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "hx7klohp");

    // we upload the file to cloudinary to easily convert it
    const url = "https://api.cloudinary.com/v1_1/q42-visio/video/upload";
    const res = await fetch(url, {
        method: "POST",
        body: formData
    });

    const data = await res.json()
    // you can change the extension of the result with the desired format, in this case we want mp3
    const mp3Url = data.secure_url.replace(data.format, 'mp3');
    // fetch the converted file
    const mp3Res = await fetch(mp3Url);
    return mp3Res.blob();
}

async function uploadToStorage(sound_id, filename, blob) {
    let file = soundsFolder.child(filename);
    return await file.put(blob);
}

function urlForSound(filename) {
    return IS_DEVELOPMENT
        ? `https://firebasestorage.googleapis.com/v0/b/hey--sounds-dev.appspot.com/o/audio%2Fquestions%2F${filename}?alt=media&token=bece4a25-dd5c-4d40-8ec3-a539d1491f36`
        : `https://firebasestorage.googleapis.com/v0/b/hey--sounds.appspot.com/o/audio%2Fquestions%2F${filename}?alt=media&token=bece4a25-dd5c-4d40-8ec3-a539d1491f36`;
}
