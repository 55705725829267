<template>
  <div>
    <h1 v-if="showDrafts" ref="header">Deze geluiden moeten nog gecontroleerd en goedgekeurd worden</h1>
    <h1 v-else ref="header">Deze geluiden zitten in het spel</h1>

    <div aria-live="polite" v-if="sounds.length > 0">
      <ul>
        <li v-for="sound in sounds" v-bind:key="sound.id">
          <router-link
            :to="{ name: 'sound.view', params: { sound_id: sound.id } }"
            >{{ sound.title }}</router-link
          >
        </li>
      </ul>
    </div>

    <div aria-live="assertive" v-else>
      <p v-if="!isLoaded">Bezig met laden...</p>
      <p v-else>Er zijn geen geluiden gevonden...</p>
    </div>

    <div>
      <router-link :to="{ name: 'welcome' }">Terug naar het begin</router-link>
    </div>
  </div>
</template>

<script>
import { questionsCollection } from "../../firestore";
import _ from "lodash";

export default {
  props: ["showDrafts"],
  data() {
    return {
      sounds: [],
      isLoaded: false
    };
  },
  mounted() {
    this.$refs.header.focus();
  },
  created() {
    questionsCollection.onSnapshot(snapshot => {
      let sounds = [];
      snapshot.forEach(doc => {
        let sound = doc.data();
        sound.id = doc.id;
        // filter out all answers which are not truethy
        // empty string for example
        if (
          sound.answers.length == 3 &&
          _.every(sound.answers, a => !!a) &&
          this.showDrafts === sound.draft
        ) {
          const correctAnswer = sound.answers[2];
          sound.title = correctAnswer;
          sounds.push(sound);
        }
      });

      this.sounds = sounds;
      this.isLoaded = true;
    });
  }
};
</script>
