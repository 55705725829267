import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const localConfig = {
  apiKey: "AIzaSyCZ-H4L_Y_cXvahrPDU-6MMo--voJT0thc",
  authDomain: "hey--sounds-dev.firebaseapp.com",
  projectId: "hey--sounds-dev",
  storageBucket: "hey--sounds-dev.appspot.com",
  messagingSenderId: "152894214353",
  appId: "1:152894214353:web:e42a6e559369ae6dc2f5f0"
}

const prodConfig = {
  apiKey: "AIzaSyCR15o_d-EZsIgq4OFILQOy1vC5iZO-Qsw",
  authDomain: "hey--sounds.firebaseapp.com",
  projectId: "hey--sounds",
  storageBucket: "hey--sounds.appspot.com",
  messagingSenderId: "48133230585",
  appId: "1:48133230585:web:9dbed37921713ce952b728"
};

const firebaseConfig = IS_DEVELOPMENT ? localConfig : prodConfig;


export const firebaseApp = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
export const soundsFolder = storage.ref().child('audio/questions');

