<template>
  <div aria-live="polite">
    <record v-on:newSound="onNewSound" />
    <button v-if="sound != null" @click="uploadRecordedSound">
      Antwoord invullen
    </button>
    <router-link class="link" :to="{ name: 'welcome' }">
      Terug naar het begin
    </router-link>
  </div>
</template>

<script>
import { createQuestion } from "../../firestore";
import { convertAndUploadFile } from "../../storage/upload";
import record from "./Record.vue";

export default {
  components: {
    record
  },
  data() {
    return {
      sound: null
    };
  },
  methods: {
    async onNewSound(val) {
      this.sound = val;
    },
    async uploadRecordedSound() {
      if (!this.sound) {
        return;
      }
      this.$announcer.assertive("Bezig met uploaden van het opgenomen geluid");
      const url = await convertAndUploadFile(
        this.sound.sound_id,
        this.sound.file
      );
      await createQuestion(this.sound.sound_id, {
        draft: true,
        answers: ["", "", ""],
        soundEffectUrl: url
      });
      this.$announcer.assertive(
        "Geluid is geuploaded. We gaan nu de antwoorden toevoegen."
      );
      this.$router.push({
        name: "sound.answer.create",
        params: {
          sound_id: this.sound.sound_id,
          answer_index: 2
        }
      });
    }
  }
};
</script>

<style scoped>
.link {
  display: block;
  margin-top: 20px;
}
</style>
