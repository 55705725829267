<template>
  <div>
    <div aria-live="polite">
      <button
        ref="record_button"
        :disabled="state == 'DISABLED'"
        @click="buttonPressed"
      >
        {{ buttonLabelForState(state) }}
      </button>
      <p v-if="state == 'RECORDING'">Geluid wordt opgenomen</p>
    </div>

    <audio id="countdown" src="/countdown.mp3" preload="auto"></audio>
    <audio id="ping" src="/go.mp3" preload="auto"></audio>
    <audio id="player" v-if="sound != null" :src="sound.href"></audio>
    <p>
      <button v-if="sound != null" @click="playRecordedSound">
        Opname beluisteren
      </button>
    </p>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      state: "DISABLED", // 'DISABLED' | 'IDLE' | 'RECORDING'
      devices: [],
      buttonLabel: "Opname starten",
      mediaRecorder: null,
      selectedDevice: 0,
      mediaStream: null,
      sound: null // { href: URL, filename: string, file: File }
    };
  },
  unmounted() {
    window.removeEventListener("keydown", this.keyDown);
    if (this.mediaStream) {
      this.stopRecording();
    }
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
    this.$refs.record_button.focus();
  },
  created() {
    const getDevices = () => {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        this.devices = devices.filter(d => d.kind === "audioinput");
        this.state = "IDLE";
      });
    };

    const requestPermission = () => {
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then(stream => {
          stream.getTracks().forEach(track => track.stop());
          this.recordingEnabled = true;
        })
        .catch(err => {
          alert(
            "Toegang tot de microfoon is vereist om het geluid op te kunnen nemen."
          );
          console.log(err);
          this.state = "DISABLED";
        })
        .then(() => {
          getDevices();
        });
    };

    requestPermission();
  },

  methods: {
    keyDown(e) {
      if (e.key == " ") {
        e.preventDefault();
        this.buttonPressed();
      }
    },
    microphoneSelectedChange(event) {
      let index = event.target.selectedIndex;
      this.selectedDevice = index;
    },
    buttonLabelForState(state) {
      switch (state) {
        case "DISABLED":
          return "Opname starten";
        case "IDLE":
          if (this.sound) {
            return "Opname opnieuw starten";
          } else {
            return "Opname starten";
          }
        case "RECORDING":
          return "Opname stoppen";
        default:
          return "fout";
      }
    },
    buttonPressed() {
      switch (this.state) {
        case "DISABLED":
          break; // Do nothing
        case "IDLE":
          this.startRecordingCountdown();
          break;
        case "RECORDING":
          this.stopRecording();
          break;
        default:
          console.log(`Unknown state: ${this.state}`);
          break;
      }
    },
    processStream(stream) {
      this.startRecordingWithStream(stream);
      this.mediaStream = stream;
      return stream;
    },
    stopStream(stream) {
      stream.getTracks().forEach(track => track.stop());
    },
    stopRecording() {
      console.log("Stopping recording");
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.stopStream(this.mediaStream);
      }

      this.$refs.record_button.focus();

      this.mediaStream = null;
      this.state = "IDLE";
    },
    startRecordingCountdown() {
      const countdown = this.$el.querySelector("#countdown");
      countdown.onended = () => {
        this.startRecording();
      };
      countdown.play();
    },
    startRecording() {
      const device = this.devices[this.selectedDevice];
      console.log(`Starting to record from device ID ${device.label}`);
      const audio = { deviceId: device.deviceId };
      navigator.mediaDevices
        .getUserMedia({ audio: audio, video: false })
        .then(this.processStream)
        .then(stream => {
          this.startRecordingWithStream(stream);
        })
        .catch(error => {
          console.log("getUserMedia error occurred");
          console.log(error);
          alert(
            "Er is iets mis gegaan bij het starten van de opname. Controleer alsjeblieft of deze website toegang tot de microfoon heeft en herlaad de pagina."
          );
          this.state = "IDLE";
        });
    },
    startRecordingWithStream(stream) {
      const ping = this.$el.querySelector("#ping");

      this.$refs.record_button.focus();

      let mimeType;
      let extension;
      if (MediaRecorder.isTypeSupported("audio/webm")) {
        mimeType = "audio/webm";
        extension = "webm";
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        mimeType = "audio/mp4";
        extension = "mp4";
      } else {
        mimeType = "audio/webm";
        extension = "wav";
      }
      const recordedChunks = [];
      const mediaRecorder = new MediaRecorder(stream, { mimeType });

      ping.play();

      ping.onended = () => {
        this.state = "RECORDING";
        this.mediaRecorder.start();
      };

      mediaRecorder.addEventListener("dataavailable", e => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }
      });

      mediaRecorder.addEventListener("stop", () => {
        console.log("Finalizing recording");
        const blob = new Blob(recordedChunks, { type: mimeType });
        const sound_id = uuidv4();
        const sound = {
          sound_id: sound_id,
          file: new File([blob], `${sound_id}.${extension}`, {
            type: mimeType
          }),
          href: URL.createObjectURL(blob),
          filename: `${sound_id}.${extension}`
        };

        this.sound = sound;
        this.state = "IDLE";
      });

      this.mediaRecorder = mediaRecorder;
    },
    playRecordedSound() {
      const player = this.$el.querySelector("#player");
      player.play();
    }
  },
  watch: {
    sound(val) {
      this.$emit("newSound", val);
    }
  }
};
</script>

<style scoped>
audio {
  display: none;
}
</style>
