<template>
  <div>
    <record v-on:newSound="onNewSound" />
    <p><button v-if="sound != null" @click="uploadRecordedSound">Geluid vervangen</button></p>
    <p>
      <router-link :to="{ name: 'sound.edit', params: { sound_id: this.sound_id}}">Terug naar de vorige pagina</router-link>
    </p>
  </div>
</template>

<script>
import { updateQuestion } from '../../firestore';
import { convertAndUploadFile } from '../../storage/upload';
import record from './Record.vue';

export default {
    components: {
        record
    },
  props: {
    sound_id: String,
  },
  data() {
    return {
      sound: null, // { href: URL, filename: string, file: File }
    }
  },
  methods: {
    async onNewSound(val) {
      this.sound = val;
    },
    async uploadRecordedSound() {
      if (!this.sound) {
        return;
      }

      this.$announcer.assertive("Bezig met uploaden");

      const url = await convertAndUploadFile(this.sound_id, this.sound.file)
      await updateQuestion(this.sound_id, {
          soundEffectUrl: url,
      });
      this.$announcer.assertive("Het geluid is aangepast.");
      this.$router.push({ name: 'sound.view', params: { 
        sound_id: this.sound_id,
      } });
    }
  }
};
</script>
