<template>
  <div aria-live="polite">
    <h1 ref="header">Wat wil je aanpassen?</h1>
    <template v-if="sound">
      <ul>
        <li>
          <button @click="playAudio">
            Het geluid beluisteren
          </button>
        </li>
        <li v-if="this.sound.draft">
          <button @click="saveAudio">
            Het geluid goedkeuren
          </button>
        </li>
        <li>
          <router-link
            :to="{
              name: 'sound.answer.edit',
              params: { sound_id: sound_id, answer_index: 2 }
            }"
          >
            Het goede antwoord veranderen
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'sound.answer.edit',
              params: { sound_id: sound_id, answer_index: 1 }
            }"
          >
            Het foute antwoord veranderen
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              name: 'sound.answer.edit',
              params: { sound_id: sound_id, answer_index: 0 }
            }"
          >
            Het grappige antwoord veranderen
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'sound.edit', params: { sound_id: sound_id } }"
          >
            Het geluid aanpassen
          </router-link>
        </li>
        <li>
          <button @click="deleteAudio">
            Het geluid verwijderen
          </button>
        </li>
        <li>
          <router-link :to="{ name: backLink }">
            Terug naar de vorige pagina
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import {
  questionsCollection,
  deleteQuestion,
  updateQuestion
} from "../../firestore";
import { playAudio } from "../../methods/audio";

export default {
  props: {
    sound_id: String
  },
  data() {
    return {
      sound: null,
      audioUrl: ""
    };
  },
  computed: {
    backLink() {
      return this.sound && this.sound.draft ? "sound.draft.list" : "sound.list";
    }
  },
  mounted() {
    this.$refs.header.focus();
  },
  async created() {
    const doc = await questionsCollection.doc(this.sound_id).get();
    if (doc.id === this.sound_id) {
      this.sound = doc.data();
      this.audioUrl = doc.data().soundEffectUrl;
    } else {
      console.error("Question not found");
    }
  },
  methods: {
    async playAudio() {
      await playAudio(this.audioUrl);
    },
    async saveAudio() {
      if (window.confirm("Weet je zeker dat je het geluid wilt goedkeuren?")) {
        await updateQuestion(this.sound_id, { draft: false });
        await this.$router.push({ name: "welcome" });
      }
    },
    async deleteAudio() {
      if (window.confirm("Weet je zeker dat je het geluid wilt verwijderen?")) {
        await deleteQuestion(this.sound_id);
        await this.$router.push({ name: "welcome" });
      }
    }
  }
};
</script>
