<template>
  <div>
    <label ref="upload"
      >Geluid uploaden
      <input
        type="file"
        accept=".mp3,.mp4,.m4a,.webm,.aac,.aiff,.ogg,.wav"
        @change="onFileSelect"
      />
    </label>

    <p>
      <router-link
        :to="{ name: 'sound.edit', params: { sound_id: this.sound_id } }"
        >Terug naar de vorige pagina</router-link
      >
    </p>
  </div>
</template>

<script>
import { updateQuestion } from "../../firestore";
import { convertAndUploadFile } from "../../storage/upload";

export default {
  props: {
    sound_id: String
  },
  mounted() {
    this.$refs.input.focus();
  },

  data() {
    return {
      sound: null, // { href: URL, filename: string, file: File }
      uploading: false
    };
  },
  methods: {
    async onFileSelect(event) {
      this.uploading = true;

      const files = event.target.files;
      const file = files[0];

      if (!file) {
        return;
      }

      if (file.size >= 10485760) {
        alert("Dit bestand is te groot, upload een kleiner bestand.");
        return;
      }

      const sound = {
        sound_id: this.sound_id,
        file,
        href: URL.createObjectURL(file)
      };

      await this.uploadSound(sound);
    },
    async uploadSound(sound) {
      this.$announcer.assertive("Bezig met uploaden");
      const url = await convertAndUploadFile(this.sound_id, sound.file);
      this.uploading = false;
      await updateQuestion(this.sound_id, {
        soundEffectUrl: url
      });
      this.$announcer.assertive("Het geluid is aangepast.");
      this.$router.push({
        name: "sound.view",
        params: {
          sound_id: this.sound_id
        }
      });
    }
  }
};
</script>
