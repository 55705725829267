<template>
  <div>
    <div aria-live="polite">
      <h1 ref="header">{{ answerData(answer_index).title }}</h1>
      <p>{{ answerData(answer_index).description }}</p>
    </div>

    <form @submit.prevent="submitAnswer">
      <label for="answer">Antwoord</label>
      <input
        type="text"
        name="answer"
        id="answer"
        v-model="answers[answer_index]"
      />
      <input type="submit" value="Antwoord opslaan" />
    </form>

    <p>
      <router-link :to="backLink">{{ backLabel }}</router-link>
    </p>
  </div>
</template>

<script>
import { updateQuestion, questionsCollection } from "../../firestore";

const answerData = {
  correctAnswer: {
    title: "Wat is het voor geluid?",
    description: "Typ hieronder wat het voor geluid is."
  },
  incorrectAnswer: {
    title: "En wat is een geluid wat er erg op lijkt?",
    description: "Dit gebruiken we als fout antwoord voor de quiz."
  },
  funnyAnswer: {
    title: "En wat is een grappig fout antwoord?",
    description:
      "Geef een omschrijving van het geluid, wat helemaal niet klopt, maar wel grappig is."
  }
};
const answerDataByIndex = [
  answerData.funnyAnswer,
  answerData.incorrectAnswer,
  answerData.correctAnswer
];
const answerDataNotFound = {
  title: "Niet gevonden",
  description: "Oeps, dit antwoord kan niet worden ingevuld."
};

export default {
  props: ["sound_id", "answer_index", "isEditing"],
  data() {
    let data = answerDataByIndex[parseInt(this.answer_index)];
    data.answers = ["", "", ""];
    return data;
  },
  mounted() {
    this.$refs.header.focus();
  },
  created() {
    const getRef = () => {
      return questionsCollection.doc(this.sound_id);
    };
    let docRef = getRef();
    docRef.get().then(doc => {
      let data = doc.data();
      let index = parseInt(this.answer_index);
      if (data.answers && data.answers.indexOf(index)) {
        this.answers = data.answers;
      }
    });
  },
  computed: {
    backLink() {
      if (this.isEditing) {
        return { name: "sound.view" };
      } else if (this.answer_index == 2) {
        return { name: "welcome" }; // because item is already created
      } else if (this.answer_index == 1) {
        return {
          name: "sound.answer.create",
          params: {
            sound_id: this.sound_id,
            answer_index: 2
          }
        };
      } else if (this.answer_index == 0) {
        return {
          name: "sound.answer.create",
          params: {
            sound_id: this.sound_id,
            answer_index: 1
          }
        };
      } else {
        return { name: "welcome" };
      }
    },
    backLabel() {
      if (this.isEditing || this.answer_index) {
        return "Terug naar de vorige pagina";
      } else {
        return "Terug naar het begin";
      }
    }
  },
  methods: {
    answerData(index_str) {
      let index = parseInt(index_str);
      if (answerDataByIndex.indexOf(index)) {
        return answerDataByIndex[index];
      } else {
        return answerDataNotFound;
      }
    },
    async submitAnswer() {
      let index = parseInt(this.answer_index);
      const getRef = () => {
        return questionsCollection.doc(this.sound_id);
      };
      let docRef = getRef();
      docRef
        .get()
        .then(doc => {
          if (doc.exists) {
            // let isDraft = !(this.answers[0].length > 0 && this.answers[1].length > 0 && this.answers[2].length > 0);
            updateQuestion(this.sound_id, { answers: this.answers })
              .then(() => console.log("doc updated with new answer!"))
              .then(() => this.continue(index));
          }
        })
        .catch(error => console.log("unable to get answer " + error));
    },
    continue(index) {
      if (this.isEditing) {
        this.$router.push({
          name: "sound.view",
          params: { sound_id: this.sound_id }
        });
      } else {
        let nextIndex = index - 1;
        if (nextIndex < 0) {
          console.log("Done, going back to lister");
          this.$router.push({ name: "welcome" }); // done
        } else {
          // Next answer
          console.log(`Going to next answer ${nextIndex}`);
          this.$router.push({
            name: "sound.answer.create",
            params: {
              sound_id: this.sound_id,
              answer_index: nextIndex.toString()
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped></style>
