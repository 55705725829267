<template>
  <div>
    <label ref="upload">
      Geluid uploaden
      <input
        type="file"
        accept=".mp3,.mp4,.m4a,.webm,.aac,.aiff,.ogg,.wav"
        @change="onFileSelect"
      />
    </label>
    <router-link class="link" :to="{ name: 'welcome' }">
      Terug naar het begin
    </router-link>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { convertAndUploadFile } from "../../storage/upload";
import { createQuestion } from "../../firestore";

export default {
  data() {
    return {
      file: null,
      sound: null,
      uploading: false,
      audioUrl: ""
    };
  },
  mounted() {
    this.$refs.upload.focus();
  },
  methods: {
    async onFileSelect(event) {
      this.uploading = true;
      const files = event.target.files;
      const file = files[0];

      if (!file) {
        return;
      }

      if (file.size >= 10485760) {
        alert("Dit bestand is te groot, upload een kleiner bestand.");
        return;
      }

      const sound_id = uuidv4();
      const sound = {
        sound_id: sound_id,
        file,
        href: URL.createObjectURL(file)
      };
      this.sound = sound;

      this.uploadSound(sound);
    },
    async uploadSound(sound) {
      this.$announcer.assertive("Bezig met geluid uploaden");
      const url = await convertAndUploadFile(sound.sound_id, sound.file);
      try {
        this.audioUrl = url;
        this.uploading = false;
        await createQuestion(sound.sound_id, {
          draft: true,
          answers: ["", "", ""],
          soundEffectUrl: url
        });
        this.$announcer.assertive(
          "Gelukt! Geluid is geupload. We gaan nu antwoorden toevoegen."
        );
        this.inputAnswers();
      } catch (err) {
        console.log("Failed to upload..." + err);
      }
    },
    inputAnswers() {
      this.$router.push({
        name: "sound.answer.create",
        params: {
          sound_id: this.sound.sound_id,
          answer_index: 2
        }
      });
    }
  }
};
</script>

<style scoped>
.link {
  display: block;
  margin-top: 20px;
}
</style>
