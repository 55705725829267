<template>
  <div aria-live="polite">
    <button ref="listen_button" @click="playAudio">
      Geluid beluisteren
    </button>
    <router-link
      class="link"
      :to="{ name: 'sound.edit.record', params: { sound_id: sound_id } }"
    >
      Aanpassen met nieuwe opname
    </router-link>
    <router-link
      class="link"
      :to="{ name: 'sound.edit.upload', params: { sound_id: sound_id } }"
    >
      Aanpassen door uploaden
    </router-link>
    <router-link
      class="link"
      :to="{ name: 'sound.view', params: { sound_id: sound_id } }"
    >
      Terug naar de vorige pagina
    </router-link>
  </div>
</template>

<script>
import { questionsCollection } from "../../firestore";
import { playAudio } from "../../methods/audio";

export default {
  props: {
    sound_id: String
  },
  data() {
    return {
      sound: null,
      audioUrl: ""
    };
  },
  mounted() {
    this.$refs.listen_button.focus();
  },
  async created() {
    const doc = await questionsCollection.doc(this.sound_id).get();
    if (doc.exists) {
      this.sound = doc.data();
      this.audioUrl = doc.data().soundEffectUrl;
    } else {
      console.error("Question not found");
    }
  },
  methods: {
    async playAudio() {
      await playAudio(this.audioUrl);
    }
  }
};
</script>

<style scoped>
.link {
  display: block;
  margin-top: 20px;
}
</style>
