import firebase from 'firebase';
import { firebaseApp } from "./firebase";

const db = firebaseApp.firestore();
export const questionsCollection = db.collection('games/default/questions');

export async function createQuestion(sound_id, question) {
    await questionsCollection.doc(sound_id).set(question);
    // we don't need to update game version, since questions aren't automatically published
}

export async function deleteQuestion(sound_id) {
    await questionsCollection.doc(sound_id).delete();
    await bumpGameVersion();
}

export async function updateQuestion(sound_id, updateObj) {
    await questionsCollection.doc(sound_id).update(updateObj);
    await bumpGameVersion();
}

async function bumpGameVersion() {
    return db.collection('games').doc('default').update({
        version: firebase.firestore.FieldValue.increment(1)
    })
}